<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :member="mem" :button1="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>
            <div class="auth-page-right-content">
              <div class="heading">
                <ThemeButton />
                <h6>Hello Again!</h6>

                <p v-if="errorMessage != null" class="error">
                  {{ errorMessage }}
                </p>
                <p v-else>Welcome Back</p>
              </div>
              <form @keyup.enter="login(0)">
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../../assets/images/email-icon.png" alt="" />
                  </div>
                  <input type="email" class="form-control" id="email" placeholder="Business Email" autocomplete="off"
                    v-model.trim="$v.details.email.$model" :class="{
                      'is-invalid': $v.details.email.$error,
                      'is-valid': !$v.details.email.$invalid,
                    }" v-on:keyup="errorNull" />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.email.required">Bussiness email is required</span>
                    <span v-if="!$v.details.email.email">Bussiness email must be valid
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-icon">
                    <img src="../../../assets/images/password-icon.png" alt="" />
                  </div>
                  <input :type="type" class="form-control" id="password" placeholder="Password" autocomplete="off"
                    v-model.trim="$v.details.password.$model" :class="{
                      'is-invalid': $v.details.password.$error,
                      'is-valid': !$v.details.password.$invalid,
                    }" v-on:keyup="errorNull" />
                  <i v-html="btnText" id="togglePassword" @click="showPassword"></i>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.password.required">Password is required</span>
                    <span v-if="!$v.details.password.minLength">Password must have at least
                      {{ $v.details.password.$params.minLength.min }}
                      letters</span>
                    <span v-if="!$v.details.password.maxLength">Password must have at most
                      {{ $v.details.password.$params.maxLength.max }}
                      letters</span>
                  </div>
                </div>
                <button type="button" class="auth-btn" @click="login(0)" :disabled="isLoadingArray[0]">
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Login</span>
                </button>
              </form>
              <div class="account">
                <router-link to="/forgot-password" class="forgot-password">Forgot Password</router-link>
              </div>
              <div class="account">
                <router-link to="/forgot-username" class="forgot-password">Forgot Username</router-link>
              </div>
              <div class="account">
                <a href="https://support.crushcontracts.com/portal/en/kb/globalnda" target="_blank"
                  class="forgot-password">Help</a>
              </div>
              <div class="account d-md-none">
                <router-link to="/register" class="forgot-password">Register</router-link>
              </div>
            </div>
            <div class="circle-one d-block d-md-none">
              <img src="../../../assets/images/circle1.png" alt="" />
            </div>
            <div class="circle-two d-block d-md-none">
              <img src="../../../assets/images/circle2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../../components/Enterprise/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import AuthLeft from "../../../components/Enterprise/authleft/AuthLeft.vue";

export default {
  name: "EnterpriseLogin",
  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function () {
    return {
      details: {
        email: "",
        password: "",
      },
      type: "password",
      btnText: '<i class="fa fa-eye-slash"></i>',
      errorMessage: null,
      isLoadingArray: [],
      para: "Simplify your NDA. Get exclusive access.",
      mem: "Not a member yet?",
      btn: "Register",
    };
  },
  validations: {
    details: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(15),
      },
    },
  },

  computed: {
    ...mapGetters(["errors"]),
  },
  mounted() {
    this.$store.commit("setErrors", {});
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),
    login: function (index) {
      const domainDataGet = localStorage.getItem("domainData");
      const domainData = JSON.parse(domainDataGet);
      const domainId = domainData.id;

      this.errorMessage = null;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      this.sendLoginRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
           if (response.data.email_verification_status == 1) {
            this.$router.push({ name: "EnterpriseVerificationEmail" });
            return;
          }
          if ( response.data.user && response.data.user.role_id == 7 ||  response.data.user.role_id ==  8 && response.data.login_first_status == 0) {
            this.$router.push({ name: "EnterpriseAgreementDetail" });
            
          } else if (response.data.user && response.data.user.role_id !== 5 && response.data.user.role_id !== 6) {
            this.$swal({
              title: "Error: Check your domain and try again",
              icon: "info",
              showCancelButton: false,
            }).then(() => {
              this.$set(this.isLoadingArray, index, false);
              return;
            });
          } 
         
          else if (response.data.user.role_id == 6 && response.data.user.enterprise_id == domainId && response.data.user.secret_answer_status !== 1) {
            this.$router.push({ name: "EnterpriseProfile" });
          }
          else if (response.data.user.role_id == 6 && response.data.user.enterprise_id == domainId && response.data.user.secret_answer_status == 1) {
            this.$router.push({ name: "EnterpriseHomes" })
          }
          // else if (
          //   response.data.user.role_id == 4 && response.data.login_first_status == 1) {
          //   this.$router.push({ name: "EnterpriseAgreementDetail" });
          // } 
          else if (response.data.user.role_id == 5 && response.data.user.subscription_status == 1 && response.data.user.secret_answer_status !== 1) {
            this.$router.push({ name: "EnterpriseProfile" });
          } else if (response.data.user.role_id == 5 && response.data.user.subscription_status == 1) {
            this.$router.push({ name: "UsersSigned" });
          } else if (response.data.user.role_id == 5 && response.data.user.subscription_status !== 1) {
            console.log(response.data.user.subscription_status);
            this.$swal({
              title: "Payment Required",
              text: "Please complete your payment and try again later.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonText: "OK",
            }).then(() => {
              // Redirect to the "EnterpriseHome" route
              this.$router.push({ name: "EnterprisePackage" });
            });
          } else {
            this.$router.push({ name: "EnterpriseLogin" });
          }
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
          }, 500);
        }
      });
    },
    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.type = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },
    errorNull: function () {
      this.errorMessage = null;
    },
  },
};
</script>
